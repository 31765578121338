import { GraphStructure } from "../../reasoning";
import {
  LlSolution,
  ResponseTopLevelAnswer,
  filterToValidAnswers,
} from "../../reasoning-engine/reasoning-engine";
import { QuestionState } from "../../reasoning-engine/states/question";
import { createGraphStructure } from "../../reasoning/new-reasoning-explorer/create-graph-data";
import { Passage } from "../../ul/ul-element";
import { SolverNodeState } from "./solverNode";

/**
 * The final static state of a question for use in the reasoning graph. This provides a type that is implementable by the QP or RE.
 */
export type FinalQuestionState = {
  // The id of the question as a whole. Because IsItTrueThat is handled differently to What,
  // this id doesn't correspond to any key in `queryStates`; instead, the RE backend synthesises
  // a random id here. If we make solvers return Answers, this will be a valid key.
  questionId: string;
  // The top-level question that was asked
  query: Passage;
  // The "top level" (direct) answers to the `query`. These currently have a different structure
  // to the other `SolutionWithIds` because of the whole "Answer vs mapping" thing.
  topLevelAnswers: Map<string, ResponseTopLevelAnswer>;
  // The nodes of the graph, each node contains neighbour information.
  graphStructure: GraphStructure;
  allSolutions: Map<string, LlSolution[]>;
  // The solver node states are extracted from the final question state so that we can associate some UL
  // with all the nodes in the graph (solver nodes) via the parentUlQuery field.
  solverNodeStates: Map<string, SolverNodeState>;
};

export function getFinalState(
  questionState: QuestionState
): FinalQuestionState {
  const validAnswers = filterToValidAnswers(questionState.topLevelAnswers);
  return {
    graphStructure: createGraphStructure(questionState),
    topLevelAnswers: new Map(
      validAnswers.map((answer) => [answer.answer.solutionId, answer])
    ),
    query: questionState.query,
    questionId: questionState.questionId,
    allSolutions: questionState.allSolutions,
    solverNodeStates: questionState.solverNodeStates,
  };
}
