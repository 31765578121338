import {
  GetDecisionExplanationTranslationRequest,
  GetDecisionExplanationTranslationResponse,
} from "@dip/data-access/api-types";
import { dipApi } from "./dipApi";

export const { useGetDecisionExplanationTranslationQuery } =
  dipApi.injectEndpoints({
    endpoints: (builder) => ({
      getDecisionExplanationTranslation: builder.query<
        GetDecisionExplanationTranslationResponse,
        GetDecisionExplanationTranslationRequest
      >({
        query: ({ decisionId }) =>
          `/decisions/${decisionId}/explanation/translation`,
      }),
    }),
  });
