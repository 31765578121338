import { Optional, toOptional } from "../../Optional";
import { handleErrors } from "../../backend";
import { fetchRetry } from "../../backend/api-retry";
import {
  AnswerExplanation,
  SolutionMappingItem,
} from "../../reasoning-engine/reasoning-engine";
import { Passage, UlElement, Uuid } from "../../ul/ul-element";

export enum TranslationType {
  ALL = "ALL",
  GENERAL = "GENERAL",
  BRIAN = "BRIAN",
}

export enum TranslationDirection {
  UL_TO_NAT_LANG = "UL_TO_NAT_LANG",
  NAT_LANG_TO_UL = "NAT_LANG_TO_UL",
}

export enum NmtModelName {
  GENERAL = "general",
  LLM_INSTRUCT = "llm-instruct",
  T5_SMALL_POINTER_SURGE = "t5-small-pointer-surge",
  T5_SMALL_POINTER_FACT_CHECKING = "t5-small-pointer-fact-checking",
}

export type TranslatorType =
  | "nmt-translator"
  | "legacy-translator"
  | "semantic-translator"
  | "translation-resolution-translator";

export type PreferredTranslation = {
  text: string;
  ul_element: UlElement;
};

export type TranslatorOptions = {
  type: TranslationType;
  bestTranslationsPercent: number;
  sortTranslations: boolean;
  ignoreCaches: boolean;
  selectedTranslators: TranslatorType[];
  nmtTranslatorModelName: NmtModelName;
  useGroundTruthLookup: boolean;
  preferredTranslations?: PreferredTranslation[];
  storeUris?: string[];
  contextNodes?: ContextNode[];
};

export type ContextNode = {
  id: string;
  resolvedNickname: string;
  providedNickname: string;
  description: string;
  specification: string;
  expressedIn: string[];
  principalClass: string;
};

export const DEFAULT_TEMPLATE_FILTER: TemplateFiltersInterface = {
  ids: [],
  translationType: TranslationType.ALL,
  tags: [],
  fields: [],
  createdAtFrom: undefined,
  createdAtTo: undefined,
  modifiedAtFrom: undefined,
  modifiedAtTo: undefined,
};

export const areMeaningfulFilters = (filters: TemplateFiltersInterface) => {
  return (
    filters.translationType !== TranslationType.ALL ||
    filters.tags.length !== 0 ||
    filters.fields.length !== 0 ||
    filters.createdAtTo ||
    filters.createdAtFrom ||
    filters.modifiedAtTo ||
    filters.modifiedAtFrom ||
    filters.ids.length !== 0
  );
};

export const DEFAULT_TRANSLATOR_OPTIONS: TranslatorOptions = {
  type: TranslationType.GENERAL,
  bestTranslationsPercent: 100,
  sortTranslations: false,
  ignoreCaches: true,
  selectedTranslators: ["legacy-translator"],
  nmtTranslatorModelName: NmtModelName.GENERAL,
  useGroundTruthLookup: false,
};

export const mergeTranslatorOptionsWithDefaults: (
  options: Partial<TranslatorOptions>
) => TranslatorOptions = (options) => {
  return {
    ...DEFAULT_TRANSLATOR_OPTIONS,
    ...options,
  };
};

export type NatLangTranslationResult = {
  data: string[];
};

type UlTranslationResult = {
  data: UlElement[];
};

/**
 * Comment type definition
 * @typedef {Comment} Comment
 * @property {string} author - Author of the comment
 * @property {string} content - Content of the comment
 * @property {string} timestamp - UTC Date Time string as an ISO String
 */
type Comment = {
  author: string;
  content: string;
  timestamp: string;
};

export type Template = {
  id: string;
  translationType: TranslationType;
  naturalLanguage: string;
  ul: UlElement;
  intermediateRepresentation: string;
  intermediateRepresentationSimplified: string;
  unknowns: Uuid[];
  examples: Example[];
  unknownExpansions: UnknownExpansion[];
  possiblePartsOfSpeech: string[];
  tags: string[];
  dateModified: string;
  dateCreated: string;
  comments: Comment[];
};

export interface TemplateFiltersInterface {
  ids: string[];
  translationType: TranslationType;
  tags: string[];
  fields: string[];
  createdAtFrom: string | undefined;
  createdAtTo: string | undefined;
  modifiedAtFrom: string | undefined;
  modifiedAtTo: string | undefined;
}

export type Example = {
  nl: string;
  ul: UlElement;
};

export type UnknownExpansion = {
  type: string;
  unknowns: Uuid[] | undefined;
};

export type QuestionUnknownExpansion = UnknownExpansion & {
  type: "QUESTION";
  details: {
    question: Passage;
  };
};

export type REExplanationTranslationResponse = {
  summarisedTranslation: ExplanationAndIds[];
  fullTranslation: ExplanationAndIds[];
};

export type ExplanationAndIds = {
  explanation: string;
  solutionIds: string[];
};

export type IntegerRangeUnknownExpansion = UnknownExpansion & {
  type: "INTEGER_RANGE";
  details: {
    lowerBound: Optional<number>;
    upperBound: Optional<number>;
  };
};

export type UserDefinedUnknownExpansion = UnknownExpansion & {
  details: {
    json: object;
  };
};

export interface UnknownExpansionVisitor<T> {
  visitQuestion(question: QuestionUnknownExpansion): T;

  visitIntegerRange(integerRange: IntegerRangeUnknownExpansion): T;

  visitUserDefined(userDefined: UserDefinedUnknownExpansion): T;
}

/**
 * Note that this isn't like a normal visitor, as there's no UnknownExpansion::accept method to
 * recurse into the structure (because UnknownExpansion is a simple data type sent from the server).
 * Handling this is up to the caller.
 */
export function visitUnknownExpansion<T>(
  unknownExpansion: UnknownExpansion,
  visitor: UnknownExpansionVisitor<T>
): T {
  switch (unknownExpansion.type) {
    case "QUESTION":
      return visitor.visitQuestion(
        unknownExpansion as QuestionUnknownExpansion
      );
    case "INTEGER_RANGE":
      return visitor.visitIntegerRange(
        unknownExpansion as IntegerRangeUnknownExpansion
      );
    default:
      return visitor.visitUserDefined(
        unknownExpansion as UserDefinedUnknownExpansion
      );
  }
}

export default class Translator {
  private languageCode: string;
  private baseApiPath: string;

  constructor(languageCode: string, baseApiPath: string) {
    this.languageCode = languageCode;
    this.baseApiPath = baseApiPath;
  }

  public getNatLangCode() {
    return this.languageCode;
  }

  public getNaturalTranslation(
    ulElem: UlElement,
    options: Partial<TranslatorOptions> = {},
    retryCount = 0
  ): Promise<string[]> {
    const finalOptions = mergeTranslatorOptionsWithDefaults(options);
    return fetchRetry(
      `${this.baseApiPath}/translate/${this.languageCode}/ul-to-natlang`,
      {
        method: "POST",
        body: JSON.stringify({
          data: ulElem,
          options: {
            ...finalOptions,
            bestTranslationsPercent: toOptional(
              finalOptions.bestTranslationsPercent
            ),
            ignoreCaches: finalOptions.ignoreCaches,
            selectedTranslators: finalOptions.selectedTranslators,
          },
        }),
        headers: new Headers({ "Content-Type": "application/json" }),
      },
      retryCount
    )
      .then(handleErrors)
      .then((res: any) => res.json())
      .then((res: NatLangTranslationResult) => res.data);
  }

  public getUlTranslation(
    string: string,
    options: Partial<TranslatorOptions> = {},
    retryCount = 0
  ): Promise<UlElement[]> {
    const finalOptions = mergeTranslatorOptionsWithDefaults(options);

    console.log("finalOptions: ", finalOptions);

    return fetchRetry(
      `${this.baseApiPath}/translate/${this.languageCode}/natlang-to-ul`,
      {
        method: "POST",
        body: JSON.stringify({
          data: string,
          options: {
            ...finalOptions,
            bestTranslationsPercent: toOptional(
              finalOptions.bestTranslationsPercent
            ),
            ignoreCaches: finalOptions.ignoreCaches,
            selectedTranslators: finalOptions.selectedTranslators,
            useGroundTruthLookup: finalOptions.useGroundTruthLookup,
          },
        }),
        headers: new Headers({ "Content-Type": "application/json" }),
      },
      retryCount
    )
      .then(handleErrors)
      .then((res: any) => res.json())
      .then((res: UlTranslationResult) => res.data);
  }

  public getSummarisedREExplanationTranslation(
    question: string,
    solutionId: string,
    solutionMapping: SolutionMappingItem[],
    explanation: AnswerExplanation,
    translationOptions: Partial<TranslatorOptions> = {},
    retryCount = 0,
    authToken?: string
  ): Promise<REExplanationTranslationResponse> {
    const finalOptions = mergeTranslatorOptionsWithDefaults(translationOptions);
    return fetchRetry(
      `${this.baseApiPath}/translate/${this.languageCode}/re-explanation-summarise`,
      {
        method: "POST",
        body: JSON.stringify({
          question: question,
          solutionId: solutionId,
          solutionMapping: solutionMapping,
          explanation: explanation,
          options: {
            ...finalOptions,
            bestTranslationsPercent: toOptional(
              finalOptions.bestTranslationsPercent
            ),
          },
        }),
        headers: new Headers({
          "Content-Type": "application/json",
          "Sec-Fetch-Mode": "no-cors",
          ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
        }),
      },
      retryCount
    )
      .then(handleErrors)
      .then((res: any) => res.json());
  }

  public getTemplates(offset: number, limit: number): Promise<Template[]> {
    return fetch(
      `${this.baseApiPath}/translate/${this.languageCode}/templates?offset=${offset}&limit=${limit}`
    )
      .then(handleErrors)
      .then((res) => res.json());
  }

  /**
   * Updates a template if a template with matching ID already exists
   *
   * @param  template  template with populated fields to be updated in the store
   * @param skipValidateAndIR whether to skip template validation and IR generation, e.g. in cases
   *     where only template metadata such as tags are updated
   * @return Promise<Template> a promise that resolves to the added template
   */
  public updateTemplate(
    template: Template,
    skipValidateAndIR = false
  ): Promise<Template> {
    return fetch(
      `${this.baseApiPath}/translate/${this.languageCode}/templates?skipValidateAndIR=${skipValidateAndIR}`,
      {
        method: "POST",
        body: JSON.stringify(template),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    )
      .then(handleErrors)
      .then((res) => res.json());
  }

  /**
   * Processes unprocessed template fields into a new Template and stores it.
   *
   * <p> Processing includes extracting and substituting valid unknowns with UUIDs in the natural
   * language and processing examples into possible parts of speech strings
   *
   * @param unsubstitutedNatLang NL containing unsubstituted unknowns X in form ${X}
   * @param examples example strings of the template to be processed into possible parts of speech
   * @param translationType valid translation type for template
   * @param ul valid Ul for template
   * @param unknownExpansions valid and formatted list of unknown expansions for the template
   * @param tags list of string tags to add to the template
   * @return Promise<Template> a promise that resolves to the added template
   */
  public createTemplate(
    translationType: TranslationType,
    unsubstitutedNatLang: string,
    ul: UlElement,
    examples: Example[],
    unknownExpansions: UnknownExpansion[],
    tags: string[]
  ): Promise<Template> {
    return fetch(
      `${this.baseApiPath}/translate/${this.languageCode}/templates/create`,
      {
        method: "POST",
        body: JSON.stringify({
          translationType: translationType,
          unsubstitutedNaturalLanguage: unsubstitutedNatLang,
          ul: ul,
          examples: examples,
          unknownExpansions: unknownExpansions,
          tags: tags,
        }),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    )
      .then(handleErrors)
      .then((res) => res.json());
  }

  public deleteTemplate(templateId: string, user: string): Promise<void> {
    return fetch(
      `${this.baseApiPath}/translate/${this.languageCode}/templates/${templateId}`,
      {
        method: "DELETE",
        headers: new Headers({ "X-UnlikelyAI-User": user }),
      }
    )
      .then(handleErrors)
      .then((res) => {});
  }

  public searchTemplates(
    offset: number,
    limit: number,
    query: string,
    // True for natural language search, false for UL search
    natLangSearch: boolean,
    templateFilters: TemplateFiltersInterface = DEFAULT_TEMPLATE_FILTER
  ): Promise<Template[]> {
    const searchType = natLangSearch ? "search-natlang" : "search-ul";
    const translatorAPIWithParams = new URL(
      `${this.baseApiPath}/translate/${this.languageCode}/templates/${searchType}`
    );
    return fetch(translatorAPIWithParams.href, {
      method: "POST",
      body: JSON.stringify({
        searchTerm: query,
        offset: offset,
        limit: limit,
        ids: templateFilters.ids,
        // No parameter assumes 'ALL'
        translationType:
          templateFilters.translationType !== TranslationType.ALL
            ? templateFilters.translationType
            : null,
        tags: templateFilters.tags,
        fields: templateFilters.fields,
        createdAtFrom: templateFilters.createdAtFrom,
        createdAtTo: templateFilters.createdAtTo,
        modifiedAtFrom: templateFilters.modifiedAtFrom,
        modifiedAtTo: templateFilters.modifiedAtTo,
      }),
      headers: new Headers({ "Content-Type": "application/json" }),
    })
      .then(handleErrors)
      .then((res) => res.json());
  }

  public getTemplateCount(
    query: string,
    // True for natural language search, false for UL search
    natLangSearch: boolean,
    templateFilters: TemplateFiltersInterface = DEFAULT_TEMPLATE_FILTER
  ): Promise<number> {
    const searchType = natLangSearch ? "search-natlang" : "search-ul";
    const translatorAPIWithParams = new URL(
      `${this.baseApiPath}/translate/${this.languageCode}/templates/${searchType}/count`
    );

    return fetch(translatorAPIWithParams.href, {
      method: "POST",
      body: JSON.stringify({
        searchTerm: query,
        // No parameter assumes 'ALL'
        translationType:
          templateFilters.translationType !== TranslationType.ALL
            ? templateFilters.translationType
            : null,
        tags: templateFilters.tags,
        fields: templateFilters.fields,
        createdAtFrom: templateFilters.createdAtFrom,
        createdAtTo: templateFilters.createdAtTo,
        modifiedAtFrom: templateFilters.modifiedAtFrom,
        modifiedAtTo: templateFilters.modifiedAtTo,
      }),
      headers: new Headers({ "Content-Type": "application/json" }),
    })
      .then(handleErrors)
      .then((res) => res.json());
  }

  public getExamplesCount(
    query: string,
    // True for natural language search, false for UL search
    natLangSearch: boolean,
    templateFilters: TemplateFiltersInterface = DEFAULT_TEMPLATE_FILTER
  ): Promise<number> {
    const searchType = natLangSearch ? "search-natlang" : "search-ul";
    const translatorAPIWithParams = new URL(
      `${this.baseApiPath}/translate/${this.languageCode}/templates/${searchType}/countExamples`
    );

    return fetch(translatorAPIWithParams.href, {
      method: "POST",
      body: JSON.stringify({
        searchTerm: query,
        // No parameter assumes 'ALL'
        translationType:
          templateFilters.translationType !== TranslationType.ALL
            ? templateFilters.translationType
            : null,
        tags: templateFilters.tags,
        fields: templateFilters.fields,
        createdAtFrom: templateFilters.createdAtFrom,
        createdAtTo: templateFilters.createdAtTo,
        modifiedAtFrom: templateFilters.modifiedAtFrom,
        modifiedAtTo: templateFilters.modifiedAtTo,
      }),
      headers: new Headers({ "Content-Type": "application/json" }),
    })
      .then(handleErrors)
      .then((res) => res.json());
  }

  public getAllTags(): Promise<string[]> {
    return fetch(
      new URL(
        `${this.baseApiPath}/translate/${this.languageCode}/templates/tags`
      ).href,
      {
        method: "GET",
      }
    )
      .then(handleErrors)
      .then((res) => res.json());
  }

  public renameTag(oldName: string, newTagName: string): Promise<void> {
    return fetch(
      new URL(
        `${this.baseApiPath}/translate/${this.languageCode}/templates/tags/${oldName}`
      ).href,
      {
        method: "PUT",
        body: newTagName,
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    )
      .then(handleErrors)
      .then((res) => {});
  }

  public getAllFields(): Promise<Map<string, string>> {
    return fetch(
      new URL(
        `${this.baseApiPath}/translate/${this.languageCode}/templates/fields`
      ).href,
      {
        method: "GET",
      }
    )
      .then(handleErrors)
      .then((res) => res.json());
  }
}
