import {
  AnswerExplanation,
  UlSolution,
} from "../../reasoning-engine/reasoning-engine";
import { SolverNodeState } from "../../reasoning-engine/states/solverNode";
import { compoundId } from "./create-graph-data";
import {
  GraphStructure,
  GraphView,
  NodeWithNeighbours,
  SolverDetails,
  isQuery,
  isSolver,
} from "./reasoning-graph-types";

const MAX_NUM_OF_ADDITIONAL_GRAPH_NODES = 1000;
const MAX_SEARCH_DEPTH = 12;
const SMALL_NODE_SIZE = 20;
export const NODE_SIZE = 100;
const SOLUTION_NODE_SIZE = NODE_SIZE * 5;

export const traverseGraph = (
  graphStructure: GraphStructure,
  graphView: GraphView,
  node: NodeWithNeighbours,
  solverPath: SolverDetails[],
  solverStates: Map<string, SolverNodeState>,
  showQueries: boolean,
  parentNode?: NodeWithNeighbours,
  depth = 0
): GraphView => {
  const isInAnswerPath = (nodeId: string) => {
    return solverPath.some((info) => info.id === nodeId);
  };

  const nodeToAdd =
    isInAnswerPath(node.id) && !parentNode
      ? {
          ...node,
          color: "green",
          val: SOLUTION_NODE_SIZE,
        }
      : node;

  const targetNode: NodeWithNeighbours | undefined =
    !showQueries && isQuery(node) ? parentNode : nodeToAdd;
  if (showQueries || isSolver(node)) {
    graphView.nodes.push(nodeToAdd);
    if (parentNode) {
      graphView.links.push({
        source: nodeToAdd,
        target: parentNode,
      });
    }
  }

  const childNodes = node?.childIds?.flatMap((nodeId) => {
    const childNode =
      graphStructure.solverNodes.get(nodeId) ??
      graphStructure.queryNodes.get(nodeId);
    const parentSolverId =
      parentNode && isSolver(parentNode) ? parentNode.id : undefined;

    if (childNode) {
      const id = isSolver(childNode)
        ? compoundId(childNode.id, parentSolverId)
        : childNode.id;

      // If the node is a solver, it will be a node in the graph, but it may not get any UL to display
      // if it is not part of the solution so we fall back to the parent query's UL.
      const nonNullBackupUl = isSolver(childNode)
        ? solverStates.get(childNode.id)?.parentUlQuery ?? undefined
        : undefined;

      return [
        {
          ...childNode,
          id,
          ul: nonNullBackupUl,
          x: 250,
          y: 250,
          val: isInAnswerPath(id) ? SOLUTION_NODE_SIZE : SMALL_NODE_SIZE,
        },
      ];
    }
    return [];
  });
  const shouldContinueTraversingGraph =
    depth < MAX_SEARCH_DEPTH &&
    graphView.nodes.length < MAX_NUM_OF_ADDITIONAL_GRAPH_NODES;
  childNodes.forEach((childNode) => {
    if (isSolver(childNode) || shouldContinueTraversingGraph) {
      const solverDetails = solverPath.find((info) => info.id === childNode.id);
      const childSolverWithDetails = {
        ...childNode,
        ul: solverDetails?.ulConclusion ?? childNode.ul,
      };
      graphView = traverseGraph(
        graphStructure,
        graphView,
        childSolverWithDetails,
        solverPath,
        solverStates,
        showQueries,
        targetNode,
        depth + 1
      );
    } else if (isQuery(childNode)) {
      const grandchildIds = childNode?.childIds ?? [];
      const isRoot = !parentNode;
      const parentSolverId = isSolver(node) ? node.id : undefined;
      if (
        grandchildIds.some((grandChildId) =>
          isInAnswerPath(compoundId(grandChildId, parentSolverId))
        ) ||
        isRoot ||
        shouldContinueTraversingGraph
      ) {
        graphView = traverseGraph(
          graphStructure,
          graphView,
          childNode,
          solverPath,
          solverStates,
          showQueries,
          targetNode,
          depth + 1
        );
      }
    }
  });
  return graphView;
};

export const createGraphView = (
  graphStructure: GraphStructure,
  solverPath: Set<SolverDetails>,
  solverStates: Map<string, SolverNodeState>,
  showQueries = false
) => {
  const emptyGraph = {
    nodes: [],
    links: [],
  };
  const rootNode = graphStructure.queryNodes.get("root");
  if (rootNode) {
    const root = {
      ...rootNode,
      color: "green",
      val: SOLUTION_NODE_SIZE,
      x: 250,
      y: 250,
    };
    return traverseGraph(
      graphStructure,
      emptyGraph,
      root,
      [...solverPath],
      solverStates,
      showQueries,
      undefined,
      undefined
    );
  }
  return { nodes: [], links: [] };
};

/**
 * Traverses down from a node to the leaves of the graph, collecting all nodes on route.
 */
export const traverseGraphSimple = (
  graphStructure: Map<string, Set<string>>,
  nodeId: string,
  seenNodes: Set<string>
): string[] => {
  if (seenNodes.has(nodeId)) return [];
  const strings = graphStructure.get(nodeId);
  return [
    nodeId,
    ...[...(strings ?? [])].flatMap((childId) =>
      traverseGraphSimple(
        graphStructure,
        childId,
        new Set([nodeId, ...seenNodes])
      )
    ),
  ];
};

/**
 * Explanation steps are ordered by where the solution id comes in the tree. First nodes are divided into disjoint subtrees, these are ordered descending by the length of the path to the root. Then the nodes are ordered within the subtree by the same metric.
 */
export const orderSubtreesLeafToRoot = (
  nodes: string[],
  solutionTree: Map<string, Set<string>>
) => {
  const disjointNodes = findDisjointNodes(nodes, solutionTree);
  const disjointNodesOrdered = disjointNodes.sort(
    (a, b) =>
      traverseToRoot(solutionTree, b).length -
      traverseToRoot(solutionTree, a).length
  );
  return disjointNodesOrdered.flatMap((node) => {
    const subtree = new Set(traverseGraphSimple(solutionTree, node, new Set()));
    const childNodes = nodes.filter((node) => subtree.has(node));
    return [
      ...childNodes.sort(
        (a, b) =>
          traverseToRoot(solutionTree, b).length -
          traverseToRoot(solutionTree, a).length
      ),
    ];
  });
};

/**
 * Find nodes that do not share common children.
 * @param nodes
 * @param structure Map of node to its children
 */
export const findDisjointNodes = (
  nodes: string[],
  structure: Map<string, Set<string>>
): string[] => {
  const idToSubgraph: [string, string[]][] = nodes.map((id) => [
    id,
    traverseGraphSimple(structure, id, new Set()),
  ]);
  const subgraphsOrderedDesc = idToSubgraph.sort(
    (a, b) => a[1].length - b[1].length
  );
  const nodeMap = new Map(idToSubgraph);
  subgraphsOrderedDesc.forEach(([id, nodes]) => {
    nodes.forEach((node) => {
      if (node !== id) nodeMap.delete(node);
    });
  });
  return [...nodeMap.keys()];
};

/**
 * Picks the first common parent unless its an AND solution under a reasoning passage, then we pick the conclusion instead.
 * @param solutionTree dependencies between the solution ids.
 * @param nodes nodes to find the common parent for.
 */
export const findBestCommonParent = (
  solutionTree: Map<string, Set<string>>,
  nodes: string[]
): string | undefined => {
  if (nodes.length === 1) {
    return nodes[0];
  }
  if (nodes.length === 0) {
    return;
  }
  const pathsToRoot = nodes.map((node) => traverseToRoot(solutionTree, node));
  const shortestPath = pathsToRoot.sort((path) => path.length)[0];
  for (let i = 0; i < shortestPath.length; i++) {
    const commonNode =
      pathsToRoot.filter((path) => path.includes(shortestPath[i])).length ===
      nodes.length
        ? shortestPath[i]
        : undefined;
    if (commonNode) {
      return shortestPath[Math.min(i + 1, shortestPath.length - 1)]; // If the first node is an AND step, we want to return the conclusion of this.
    }
  }
  console.error("No common parent node found in graph for nodes: ", nodes);
};

/**
 * Traverses up the tree to the root from the node.
 * @return a list of nodes from leaf to root.
 */
export const traverseToRoot = (
  solutionTree: Map<string, Set<string>>,
  node: string
): string[] => {
  const parents = [...solutionTree]
    .filter(([_, children]) => children.has(node))
    .map(([parent, _]) => parent);
  if (parents.length > 1) {
    throw new Error("Multiple parents found");
  }
  if (parents.length === 0) {
    return [node];
  }
  return [node, ...traverseToRoot(solutionTree, parents[0])];
};

export const solutionsRecursive = (
  explanation: AnswerExplanation
): UlSolution[] => {
  return solutionsFrom(explanation).flatMap((solution) => [
    solution,
    ...solutionsRecursive(solution.explanation),
  ]);
};

export function solutionsFrom(explanation: AnswerExplanation): UlSolution[] {
  switch (explanation["@type"]) {
    case "AND":
      return explanation.clauseSolutions;
    case "OR":
      return [explanation.fromSolution];
    case "EXISTS":
      return [explanation.subSolution];
    case "BACKWARD_CHAINING":
      return [explanation.precedentSolvedAs];
    case "COMPUTED":
      return [];
    case "HYPOTHETICAL":
      return [explanation.innerSolution];
    case "KNOWLEDGE":
      return [];
    case "SEMANTIC_EQUIVALENCE":
      return [explanation.equivalentSolvedAs];
    case "PROOF_BY_CONTRADICTION":
      return [explanation.innerSolution];
    case "REDUCER":
      return explanation.combinerSolution
        ? [explanation.combinerSolution, ...explanation.subquerySolutions]
        : explanation.subquerySolutions;
  }
}
