import {
  RealtimeChannel,
  UseChannelCallback,
  useAppSelector,
  useChannel,
} from "@dip/data-access/dip-api-service";

export const useDomainChannel = (callback: UseChannelCallback) => {
  const tenantId = useAppSelector(({ auth }) => auth.tenantId);

  return useChannel(`${tenantId}:${RealtimeChannel.DOMAIN}`, callback);
};
