import { ComponentProps } from "react";
import styled from "@emotion/styled";
import { Heading, Paragraph } from "@unlikelyai-magic/ui/typography";
import { cardPaddingStyles } from "../styles/card.styles";

const CardTitle = styled(Heading)`
  font-size: ${({ theme }) => theme.fontSizes.md};
`;

const Description = styled(Paragraph)`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export type CardHeaderProps = ComponentProps<"div"> & {
  title?: string;
  description?: string;
};

export const CardHeader = styled(
  ({ title, description, ...props }: CardHeaderProps) => (
    <div {...props}>
      <CardTitle level="h3">{title}</CardTitle>
      {description && <Description>{description}</Description>}
    </div>
  )
)`
  ${cardPaddingStyles};
`;
