import { PropsWithChildren } from "react";
import { config } from "@dip/config";
import * as Ably from "ably";
import { AblyProvider } from "ably/react";
import { useAppSelector } from "../hooks";

export const AblyProviderWrapper = ({ children }: PropsWithChildren) => {
  const accessToken = useAppSelector(({ auth }) => auth.accessToken);
  const ablyClient = new Ably.Realtime({
    authUrl: `${config.DIP_API_BASE_URL}/realtime/token`,
    authMethod: "POST",
    authHeaders: { authorization: `Bearer ${accessToken}` },
  });

  return <AblyProvider client={ablyClient}>{children}</AblyProvider>;
};
