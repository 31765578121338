import { config } from "@dip/config";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from "@reduxjs/toolkit/dist/query/react";
import { RootState } from "./store";

export const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) =>
  fetchBaseQuery({
    baseUrl: config.DIP_API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { auth } = getState() as RootState;
      if (auth.accessToken)
        headers.set("Authorization", `Bearer ${auth.accessToken}`);
    },
  })(args, api, extraOptions);
