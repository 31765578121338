import { ApplicationConfig, ENV } from "../types";

export const config: ApplicationConfig = {
  AUTH0: {
    AUDIENCE: "https://app.dip.test.unlikely.ai/api",
    CLIENT_ID: "E7o8944hMl8YPvqfdaqB6FqsAlrHOQb8",
    DOMAIN: "unlikelyai-test.uk.auth0.com",
  },
  DIP_API_BASE_URL: "https://api.dip.test.unlikely.ai/api/v1",
  ENV: ENV.TEST,
  LAUNCH_DARKLY_CLIENT_TOKEN: "609bfeb54a15770c83d293ef",
};
