import { SettingsSidebarProvider } from "@dip/config";
import { theme } from "@dip/theme";
import { ThemeProvider } from "@emotion/react";
import { ModalProvider } from "@unlikelyai-magic/ui/modals";
import { AppRouter } from "./AppRouter";

export default () => (
  <ThemeProvider theme={theme}>
    <ModalProvider>
      <SettingsSidebarProvider>
        <AppRouter />
      </SettingsSidebarProvider>
    </ModalProvider>
  </ThemeProvider>
);
