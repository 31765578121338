import { ComponentProps } from "react";
import styled from "@emotion/styled";
import { cardPaddingStyles } from "../styles/card.styles";
import { CardHeader } from "./CardHeader";

export const CardBody = styled((props: ComponentProps<"div">) => (
  <div {...props} />
))`
  ${cardPaddingStyles};
  // Add text truncation for direct paragraph children

  > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${CardHeader} + & {
    padding-top: 0;
  }

  &:not(:last-child) {
    padding-bottom: 0;
  }
`;
