import { ComponentProps } from "react";
import styled from "@emotion/styled";

export type CardContainerProps = ComponentProps<"div">;

export const CardContainer = styled((props: CardContainerProps) => (
  <div {...props} />
))`
  background-color: ${({ theme }) => theme.colors.background.default};
  border-radius: ${({ theme }) => theme.roundness.md};
  box-shadow: ${({ theme }) => theme.shadows.md};
`;
