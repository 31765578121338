import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useSettingsSidebar } from "@dip/config";
import {
  DocumentContentDto,
  FeedbackRequestedFrom,
} from "@dip/data-access/api-types";
import {
  RealtimeChannel,
  useAppSelector,
  useCreateFeedbackMutation,
  useGetDomainQuery,
} from "@dip/data-access/dip-api-service";
import { theme } from "@dip/theme";
import { PromptInput } from "@dip/ui/components/inputs";
import { PageSpinner } from "@dip/ui/components/spinners";
import { Tabs, TabsProps } from "@dip/ui/components/tabs";
import { CodeTextBox, NLTextBox } from "@dip/ui/components/text-boxes";
import styled from "@emotion/styled";
import { XMarkIcon } from "@heroicons/react/16/solid";
import { ArrowUturnRightIcon } from "@heroicons/react/24/outline";
import { ChannelProvider } from "ably/react";
import { Col, Row } from "antd";
import moment from "moment";
import { TransparentButton } from "@unlikelyai-magic/ui/buttons";
import { Icon } from "@unlikelyai-magic/ui/icons";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Paragraph } from "@unlikelyai-magic/ui/typography";
import { screenBreakpoints } from "@unlikelyai-magic/ui/variables";
import { UnderstandingLoadingCard } from "../UnderstandingLoadingCard";
import { DomainNodeCard } from "./DomainNodeCard";

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  // 3rem is the size of the header bar
  height: calc(100vh - 3rem);

  @media (min-width: ${screenBreakpoints.lg}) {
    grid-template-columns: 0.5fr 1fr;
  }
`;

const StyledRow = styled(Row)`
  width: 100%;
  padding: ${({ theme }) => theme.spacings.xl};
`;

const Code = styled.code`
  white-space: pre-line;
  max-height: 6.25rem;
  overflow: auto;
`;

const Representation = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 0.0625rem solid
    ${({ theme }) => theme.colors.component.border[1]};
  /* TODO: We probably shouldn't use vh here, but this appears to be the only height setting that fixes the height when changing tabs.
  https://linear.app/unlikelyai/issue/UA-402/ul-tab-creates-a-scroll-on-the-whole-page-when-content-overflows */
  height: calc(100vh - 3rem);
`;

const RepresentationTabs = styled(Tabs)`
  .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-tab-btn {
    padding: 0 1rem;
  }

  .ant-tabs-content-holder {
    overflow: auto;
  }

  height: 100%;
  overflow: hidden;
`;

const ProvideFeedbackInput = styled(PromptInput)`
  border-top: 0.0625rem solid ${({ theme }) => theme.colors.component.border[1]};
  border-bottom: 0.0625rem solid
    ${({ theme }) => theme.colors.component.border[1]};
`;

const UpdatedAt = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.text.secondary.default};
  padding: ${({ theme }) => theme.spacings.md}
    ${({ theme }) => theme.spacings.lg};
`;

interface TopLevelLayoutProps {
  descriptionFieldName: keyof DocumentContentDto;
  naturalLanguageFieldName: "exclusionsCommentary" | "representationCommentary";
  universalLanguageFieldName: "exclusions" | "representation";
}

export const TopLevelLayout = ({
  descriptionFieldName,
  naturalLanguageFieldName,
  universalLanguageFieldName,
}: TopLevelLayoutProps) => {
  const { domainId } = useParams();
  const tenantId = useAppSelector(({ auth }) => auth.tenantId);
  const { data } = useGetDomainQuery(
    { id: domainId || "" },
    { skip: !domainId }
  );
  const [selectedRepresentation, setSelectedRepresentation] =
    useState<string>();

  const [createFeedback] = useCreateFeedbackMutation();
  const { numOfRefinements } = useSettingsSidebar();

  const versions = data?.understandings[0].versions;
  const latestVersion = versions?.[versions.length - 1];
  const showFeedbackInput =
    // If feedback is requested from a human, show the input
    latestVersion?.feedbackRequestedFrom === FeedbackRequestedFrom.HUMAN ||
    // If we are done understanding, we should still show the input because
    // the user may still have feedback to apply.
    latestVersion?.feedbackRequestedFrom === null;

  // We assume there is only one understanding per domain. Eventually we will support multiple
  // understandings and this will need to be updated.
  const understandingId = data?.understandings[0].id ?? "";

  const handleFeedback = async (value: string) => {
    if (!data) return;

    const feedback = selectedRepresentation
      ? `In the representation snippet '''${selectedRepresentation}''' apply the feedback '${value}'`
      : value;

    await createFeedback({
      feedback,
      domainId: data.id,
      domainUnderstandingId: understandingId,
      refinements: numOfRefinements,
    });

    // Clear the selected representation
    setSelectedRepresentation(undefined);
  };

  /**
   * Handles the selection of a representation snippet. Does not clear the selection, this is
   * done explicitly with the clear button.
   */
  const handleSelection = useCallback(
    (selection?: string) => selection && setSelectedRepresentation(selection),
    []
  );

  const tabItems: TabsProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: "Natural language",
        children: (
          <CodeTextBox
            value={(latestVersion?.[naturalLanguageFieldName] ?? []).map(
              (rule, i) => `Rule ${i + 1}: ${rule}`
            )}
          />
        ),
      },
      {
        key: "2",
        label: "Universal language",
        children: (
          <CodeTextBox
            value={(latestVersion?.[universalLanguageFieldName] ?? []).map(
              (rule, i) => `Rule ${i + 1}: ${rule}`
            )}
            onSelectionChange={handleSelection}
          />
        ),
      },
      {
        key: "3",
        label: "Nodes",
        children: (
          <StyledRow gutter={[16, 16]}>
            {(latestVersion?.nodes ?? [])
              .slice()
              .sort((a, b) => a.nickname.localeCompare(b.nickname))
              .map((node) => (
                <Col xs={24} md={12} xxl={8} key={node.nodeId}>
                  <DomainNodeCard node={node} />
                </Col>
              ))}
          </StyledRow>
        ),
      },
    ],
    [
      handleSelection,
      latestVersion,
      naturalLanguageFieldName,
      universalLanguageFieldName,
    ]
  );

  if (!data) return <PageSpinner />;

  return (
    <Layout>
      <NLTextBox
        header="Description"
        content={data.content[descriptionFieldName]}
      />
      {latestVersion ? (
        <Representation>
          <RepresentationTabs
            items={tabItems}
            onChange={() => setSelectedRepresentation(undefined)}
            tabBarStyle={{ margin: 0 }}
          />
          {showFeedbackInput && (
            <ProvideFeedbackInput
              placeholder="Provide feedback..."
              placeholderColor={theme.colors.text.primary.default}
              onSubmit={handleFeedback}
              header={
                selectedRepresentation && (
                  <Spacing
                    direction="horizontal"
                    justify="space-between"
                    items="center"
                  >
                    <Spacing direction="horizontal" gap="sm" items="center">
                      <Icon icon={ArrowUturnRightIcon} size="1em" />
                      <Code>{selectedRepresentation}</Code>
                    </Spacing>
                    <TransparentButton
                      size="sm"
                      icon={<XMarkIcon />}
                      onClick={() => setSelectedRepresentation(undefined)}
                    />
                  </Spacing>
                )
              }
            />
          )}
          <UpdatedAt small>{`Last updated ${moment(
            new Date(latestVersion.dateCreated)
          ).fromNow()}`}</UpdatedAt>
        </Representation>
      ) : (
        <ChannelProvider
          channelName={`${tenantId}:${RealtimeChannel.PIPELINE}:${understandingId}`}
          /* Get the previous Ably message so we know which state the domain understanding is in. This ensures
            the backend does not have to write the status of the domain understanding to the database each time the status
            changes. The change in status is already encoded in Ably, and so we can simply pull it from there. */
          options={{ params: { rewind: "1" } }}
        >
          <UnderstandingLoadingCard domainUnderstandingId={understandingId} />
        </ChannelProvider>
      )}
    </Layout>
  );
};
