import { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { ENV, config } from "@dip/config";
import { store } from "@dip/data-access/dip-api-service";
import { createMockServer } from "@unlikelyai-magic/shared/mocking";
import App from "./app/App";
import "./app/App.css";
import { setupDatadog } from "./logging/datadog";
import { mockDipApi } from "./msw/mswHandlers";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const render = async () => {
  // do not send any logs to DataDog while in development mode
  if (config.ENV !== ENV.DEVELOPMENT) setupDatadog();

  // setup MSW endpoints
  if (config.ENV === ENV.DEVELOPMENT) {
    const mockServer = createMockServer(mockDipApi);
    await mockServer.listen();
  }

  root.render(
    <StrictMode>
      <Auth0Provider
        domain={config.AUTH0.DOMAIN}
        clientId={config.AUTH0.CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: config.AUTH0.AUDIENCE,
        }}
      >
        <BrowserRouter>
          <Provider store={store}>
            <App />
          </Provider>
        </BrowserRouter>
      </Auth0Provider>
    </StrictMode>
  );
};

render();
