import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ForceGraph, {
  ForceGraphMethods,
  NodeObject,
} from "react-force-graph-2d";
import ResizeDetector from "react-resize-detector";
import {
  FullscreenExitOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Switch, Tooltip } from "antd";
import * as d3 from "d3";
import { NicknameDataContext } from "../../../nicknames/NicknameDataContext";
import { NicknamePair } from "../../../nicknames/nickname-pair";
import { SolverNodeState } from "../../../reasoning-engine/states/solverNode";
import { gatherAllUuids, serialiseUlElement } from "../../../ul/UlSerializer";
import { UNLIKELY_AI_SOURCE_URL } from "../../explanation";
import {
  NODE_SIZE,
  createGraphView,
  traverseGraphSimple,
} from "../graph-traversal";
import {
  GraphStructure,
  GraphView,
  NodeSources,
  NodeWithNeighbours,
  ReasoningEngineBaseNode,
  SolverDetails,
} from "../reasoning-graph-types";
import {
  calculateLabelBoxDimensions,
  nodeCanvasObject,
  truncateAllText,
} from "./nodeVisualisation";

const SMALL_ZOOM_SCALE = 0.5;
const LARGE_ZOOM_SCALE = 0.75;
const ZOOM_TO_FIT_DEFAULT_DURATION_MS = 2000;
const ZOOM_TO_FIT_LARGE_GRAPH_NODE_PADDING = 100;
const ZOOM_IN_DELAY_MS = 1000;
const ZOOM_OUT_DELAY_MS = 2000;

interface ParseTextProps {
  node: NodeObject<ReasoningEngineBaseNode>;
  labelsByNodeId: Map<string, string>;
  queryNodeLabels: Map<string, string | undefined>;
  sourcesByNodeId: Map<string, NodeSources>;
  uuidToNicknameMap: Map<string, NicknamePair | undefined>;
}

const parseText = ({
  node,
  labelsByNodeId,
  queryNodeLabels,
  sourcesByNodeId,
  uuidToNicknameMap,
}: ParseTextProps) => {
  const nlText = (
    labelsByNodeId.get(node.id) ??
    queryNodeLabels.get(JSON.stringify(node.ul)) ??
    ""
  ).trim();
  const formattedNlText = nlText.charAt(0).toUpperCase() + nlText.slice(1);
  const sources = sourcesByNodeId.get(node.id);
  const sourceUrl = sources?.sourceInformation?.length
    ? sources.sourceInformation[0].url
    : node.entityType === "COMPUTATION"
    ? UNLIKELY_AI_SOURCE_URL
    : undefined;
  const sourceText = sourceUrl ? ["Source: ", sourceUrl] : [];

  const ulText = node.ul
    ? `${serialiseUlElement(node.ul, uuidToNicknameMap, true, true)}`
    : "";

  return { natLangText: formattedNlText, sourceText, ulText };
};

interface HandleCanvasPointerAreaPaintFnProps extends ParseTextProps {
  selectedNodes: Set<ReasoningEngineBaseNode>;
  color: string;
  ctx: CanvasRenderingContext2D;
  globalScale: number;
  debugMode: boolean;
}

/**
 * Draws the area in which a user can interact (e.g. click, drag, etc)
 * with a node. In other words, this function draws an unseen canvas on the graph
 * that defines the area of user interaction for each node.
 */
const handleCanvasPointerAreaPaintFn = ({
  node,
  selectedNodes,
  labelsByNodeId,
  queryNodeLabels,
  sourcesByNodeId,
  uuidToNicknameMap,
  color,
  ctx,
  globalScale,
  debugMode,
}: HandleCanvasPointerAreaPaintFnProps) => {
  // draw an area for the node circle
  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.arc(node.x ?? 0, node.y ?? 0, NODE_SIZE, 0, 2 * Math.PI, false);
  const fontSize = 7 * Math.max(10 / (globalScale * 5), 2);
  const codeFont = `${fontSize}px monospace`;
  const defaultFont = `${fontSize}px Sans-Serif`;

  // draw an area for the label box if the label box is displayed
  if ([...selectedNodes].some(({ id }) => id === node.id)) {
    const { natLangText, sourceText, ulText } = parseText({
      node,
      labelsByNodeId,
      queryNodeLabels,
      sourcesByNodeId,
      uuidToNicknameMap,
    });

    const { truncatedNatLangText, truncatedSourceText, truncatedUlText } =
      truncateAllText({
        natLangText,
        sourceText,
        ulText,
        globalScale,
        debugMode,
        node,
        ctx,
      });
    const fontSize = 4 * Math.max(10 / (globalScale * 5), 2);
    const { labelStartX, labelStartY, labelWidth, labelHeight } =
      calculateLabelBoxDimensions({
        node,
        ctx,
        fontSize,
        text: debugMode
          ? new Map([
              [truncatedUlText, codeFont],
              [truncatedNatLangText, defaultFont],
              [truncatedSourceText, defaultFont],
            ])
          : new Map([
              [truncatedNatLangText, defaultFont],
              [truncatedSourceText, defaultFont],
            ]),
      });
    ctx.fillRect(labelStartX, labelStartY, labelWidth, labelHeight);
  }

  ctx.fill();
};

const filterGraphToSolutionNodes = (
  graphData: GraphView,
  solutionNodeIds: string[]
) => ({
  links: graphData.links.filter(
    ({ source, target }) =>
      solutionNodeIds.includes((source as NodeWithNeighbours).id) &&
      solutionNodeIds.includes((target as NodeWithNeighbours).id)
  ),
  nodes: graphData.nodes.filter((node) => solutionNodeIds.includes(node.id)),
});

export const ControlPanel = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 40px;
`;

export interface GraphProps {
  graphStructure: GraphStructure;
  hoveredNodeIds: Set<string>;
  selectedNodeIds: Set<string>;
  labelsByNodeId: Map<string, string>;
  sourcesByNodeId: Map<string, NodeSources>;
  selectedSolvers: Set<SolverDetails>;
  solverStates: Map<string, SolverNodeState>;
  queryNodeLabels: Map<string, string | undefined>;
  clearSelection: () => void;
  onInitialSolutionNodeView?: () => void;
  showUlInitial?: boolean;
}

/**
 * Deals with styling and visualisation of the graph.
 *
 * Inputs:
 * - graphStructure: The structure of the graph to be visualised
 * - selectedNodeIds: The nodes that are selected (from interaction in parent component)
 * - labelsByNodeId: Labels for nodes (can be derived from an explanation in parent component)
 * - hoveredNodeIds: The nodes that are hovered (from interaction in parent component)
 * - sourcesByNodeId: Enriching source information for nodes (can be derived from an explanation in parent component)
 * - selectedSolvers (can be derived from an explanation in parent component)
 * - queryNodeLabels: Labels for query nodes
 * - onInitialSolutionNodeView: callback executed after the solution node toggle has been executed for the first time
 */
export const Graph = ({
  graphStructure,
  hoveredNodeIds,
  selectedNodeIds,
  sourcesByNodeId,
  labelsByNodeId,
  selectedSolvers,
  solverStates,
  queryNodeLabels,
  clearSelection,
  onInitialSolutionNodeView,
  showUlInitial = false,
}: GraphProps) => {
  const [isGraphLoading, setIsGraphLoading] = useState(true);
  const [isShowSolutionNodes, setIsShowSolutionNodes] = useState(false);
  const [hasShownSolutionNodes, setHasShownSolutionNodes] = useState(false);

  const graphData = useMemo(
    () => createGraphView(graphStructure, selectedSolvers, solverStates, false),
    [graphStructure, selectedSolvers, solverStates]
  );

  const { uuidToNicknameMap, notifyUnknownUuids } =
    useContext(NicknameDataContext);
  const graphRef =
    useRef<ForceGraphMethods<NodeObject<ReasoningEngineBaseNode>>>();

  const solutionNodeIds = useMemo(
    () => [...selectedSolvers].map((solver) => solver.id),
    [selectedSolvers]
  );

  const isKnowledgeLookup = solutionNodeIds.length === 1;

  const [displayedGraphData, setDisplayedGraphData] = useState(
    !isKnowledgeLookup
      ? graphData
      : filterGraphToSolutionNodes(graphData, solutionNodeIds)
  );

  const [userHoverNodeId, setUserHoverNodeId] = useState<string | undefined>();

  const selectedNodes = useMemo(() => {
    const selected = new Set(
      graphData.nodes.filter((node) => selectedNodeIds.has(node.id))
    );
    const userHovered = new Set(
      graphData.nodes.filter((node) => userHoverNodeId === node.id)
    );

    const hovered = new Set(
      graphData.nodes.filter((node) => hoveredNodeIds.has(node.id))
    );

    return new Set([...selected, ...hovered, ...userHovered]);
  }, [graphData.nodes, userHoverNodeId, selectedNodeIds, hoveredNodeIds]);

  // Gather all nicknames used in the graph and notify the user if any are unknown
  // so that hovering UL elements can be displayed correctly
  useEffect(() => {
    const newNodesFromGraph = [...graphData.nodes.values()].flatMap((node) => [
      ...gatherAllUuids(node.ul ?? { elements: [] }),
    ]);
    const unknownUuids = [...newNodesFromGraph].filter(
      (uuid) => !uuidToNicknameMap.has(uuid)
    );

    if (unknownUuids.length) notifyUnknownUuids(unknownUuids);
  }, [graphData.nodes, uuidToNicknameMap, notifyUnknownUuids]);

  const graphViewStructure = useMemo(() => {
    return new Map(
      graphData.nodes.map((node) => {
        const linkedNodes = new Set(
          graphData.links
            .filter((l) => (l.target as NodeWithNeighbours).id === node.id)
            .map((l) => (l.source as NodeWithNeighbours).id)
        );
        linkedNodes.delete(node.id);
        return [node.id, linkedNodes];
      })
    );
  }, [graphData]);

  const userHoveredSubgraph = useMemo(
    () =>
      userHoverNodeId
        ? traverseGraphSimple(graphViewStructure, userHoverNodeId, new Set())
        : [],
    [userHoverNodeId, graphViewStructure]
  );

  const explanationHoveredSubgraph = useMemo(() => {
    return [...hoveredNodeIds].flatMap((nodeId) =>
      traverseGraphSimple(graphViewStructure, nodeId, new Set())
    );
  }, [hoveredNodeIds, graphViewStructure]);

  const selectedSubgraph = useMemo(() => {
    return [...selectedNodeIds].flatMap((nodeId) =>
      traverseGraphSimple(graphViewStructure, nodeId, new Set())
    );
  }, [selectedNodeIds, graphViewStructure]);

  const getNodeColor = useCallback(
    (node: NodeObject<NodeObject<ReasoningEngineBaseNode>>) => {
      const leafSolver =
        node.entityType === "KNOWLEDGE" || node.entityType === "COMPUTATION";
      const allHovered = [
        ...userHoveredSubgraph,
        ...explanationHoveredSubgraph,
      ];
      const leafSolverColor = "rgba(234, 179, 8, 1)";
      const aggregationSolverColor = "rgba(91, 33, 182, 1)";
      const fadedRootColor = "rgb(192,224,192)";
      const fadedLeafSolverColor = "rgba(243,224,187,0.74)";
      const fadedAggregationSolverColor = "#D0CAF7";

      // ensures the non-solution nodes are always faded
      if (!solutionNodeIds.includes(node.id)) {
        if (node.color === "green") return fadedRootColor;
        return leafSolver ? fadedLeafSolverColor : fadedAggregationSolverColor;
      }

      if (allHovered.includes(node.id)) {
        if (leafSolver) {
          return node.color ?? leafSolverColor;
        }
        return node.color ?? aggregationSolverColor;
      } else if (
        allHovered.length === 0 &&
        selectedSubgraph.includes(node.id)
      ) {
        if (leafSolver) {
          return node.color ?? leafSolverColor;
        }
        return node.color ?? aggregationSolverColor;
      } else if (!allHovered.length && !selectedSubgraph.length) {
        if (leafSolver) {
          return node.color ?? leafSolverColor;
        }
        return node.color ?? aggregationSolverColor;
      } else {
        if (node.color === "green") {
          return fadedRootColor;
        }
        if (leafSolver) {
          return fadedLeafSolverColor;
        } else {
          return fadedAggregationSolverColor;
        }
      }
    },
    [
      userHoveredSubgraph,
      explanationHoveredSubgraph,
      solutionNodeIds,
      selectedSubgraph,
    ]
  );

  // Debug mode
  const [debugMode, setDebugMode] = useState(showUlInitial);
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "D") {
        setDebugMode(!debugMode);
      }
    };
    document.addEventListener("keydown", handleKeyDown, true);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [debugMode, setDebugMode]);

  const graphZoomToFit = useCallback(
    (zoomToNodes: ReasoningEngineBaseNode[] = []) => {
      graphRef.current?.zoomToFit(
        ZOOM_TO_FIT_DEFAULT_DURATION_MS,
        ZOOM_TO_FIT_LARGE_GRAPH_NODE_PADDING,
        zoomToNodes.length ? (node) => zoomToNodes.includes(node) : undefined
      );
    },
    []
  );

  const handleZoomIn = () => {
    const newZoom = (graphRef.current?.zoom() ?? 0) * 1.2;
    graphRef.current?.zoom(newZoom);
  };

  const handleZoomOut = () => {
    const newZoom = (graphRef.current?.zoom() ?? 0) / 1.2; // Zoom out by 20%
    graphRef.current?.zoom(newZoom);
  };

  // set the forces and the zoom for the initial render of the graph
  useEffect(() => {
    if (isKnowledgeLookup) return;
    graphRef.current?.zoom(0.1);
    graphRef.current?.d3Force(
      "link",
      d3.forceLink().distance(500).strength(0.01)
    );
    graphRef.current?.d3Force("charge", d3.forceManyBody().strength(-5));
    const timeout = setTimeout(
      () => setIsGraphLoading(false),
      ZOOM_OUT_DELAY_MS
    );
    return () => clearTimeout(timeout);
  }, [isKnowledgeLookup]);

  // set new forces based on how many nodes are displayed
  useEffect(() => {
    if (isKnowledgeLookup) return;
    graphRef.current?.d3Force(
      "link",
      d3
        .forceLink()
        .distance(500)
        .strength(isShowSolutionNodes ? 0.25 : 0.01)
    );
    graphRef.current?.d3Force(
      "charge",
      d3.forceManyBody().strength(isShowSolutionNodes ? -500 : -50)
    );

    setDisplayedGraphData(
      isShowSolutionNodes
        ? filterGraphToSolutionNodes(graphData, solutionNodeIds)
        : graphData
    );
  }, [graphData, isShowSolutionNodes, solutionNodeIds, isKnowledgeLookup]);

  // automatically zoom after toggling the number of nodes on the graph
  useEffect(() => {
    if (isKnowledgeLookup) {
      graphRef.current?.d3Force(
        "link",
        d3.forceLink().distance(100).strength(1)
      );
      graphRef.current?.d3Force("charge", d3.forceManyBody().strength(-50));
      const nodeCount = graphData.nodes.length ?? 0;
      graphRef.current?.zoom(
        nodeCount > 20 ? SMALL_ZOOM_SCALE : LARGE_ZOOM_SCALE
      );
      return;
    }
    const timeout = setTimeout(
      () =>
        graphZoomToFit(
          isShowSolutionNodes
            ? displayedGraphData.nodes.filter((node) =>
                solutionNodeIds.includes(node.id)
              )
            : []
        ),
      isShowSolutionNodes ? ZOOM_IN_DELAY_MS : ZOOM_OUT_DELAY_MS
    );
    return () => clearTimeout(timeout);
  }, [
    displayedGraphData.nodes,
    graphData.nodes,
    isShowSolutionNodes,
    solutionNodeIds,
    graphZoomToFit,
    isKnowledgeLookup,
  ]);

  useEffect(() => {
    if (isShowSolutionNodes && !hasShownSolutionNodes) {
      // the timeout ensures the callback does not execute until after the zoom effect has finished
      const timeout = setTimeout(() => {
        onInitialSolutionNodeView?.();
        setHasShownSolutionNodes(true);
      }, ZOOM_IN_DELAY_MS + ZOOM_TO_FIT_DEFAULT_DURATION_MS);
      return () => clearTimeout(timeout);
    }
  }, [hasShownSolutionNodes, isShowSolutionNodes, onInitialSolutionNodeView]);

  const nodeCanvas = (
    node: NodeObject<ReasoningEngineBaseNode>,
    ctx: CanvasRenderingContext2D,
    globalScale: number
  ) => {
    if ([...selectedNodes].some((n) => node.id === n.id)) {
      const { natLangText, sourceText, ulText } = parseText({
        node,
        labelsByNodeId,
        queryNodeLabels,
        sourcesByNodeId,
        uuidToNicknameMap,
      });

      if (!natLangText && !debugMode) return;
      ctx.globalCompositeOperation = "source-over";
      nodeCanvasObject(
        node,
        ctx,
        globalScale,
        natLangText,
        sourceText,
        ulText,
        debugMode
      );
      ctx.globalCompositeOperation = "destination-over";
    }
  };

  return (
    <ResizeDetector>
      {({ width, height }) => (
        <div style={{ height: "100%", width: "100%" }}>
          <div data-cy="reasoning-graph">
            <ForceGraph
              nodeColor={getNodeColor}
              ref={graphRef}
              width={width || undefined}
              height={height || undefined}
              backgroundColor="rgba(248, 250, 252, 1)"
              graphData={displayedGraphData}
              onNodeHover={(node) => setUserHoverNodeId(node?.id)}
              onNodeClick={(node) => setUserHoverNodeId(node?.id)}
              nodePointerAreaPaint={(node, color, ctx, globalScale) =>
                handleCanvasPointerAreaPaintFn({
                  node,
                  selectedNodes,
                  labelsByNodeId,
                  queryNodeLabels,
                  sourcesByNodeId,
                  uuidToNicknameMap,
                  color,
                  ctx,
                  globalScale,
                  debugMode,
                })
              }
              linkCurvature={0.25}
              linkDirectionalParticles={2}
              linkDirectionalParticleSpeed={0.003}
              nodeCanvasObject={nodeCanvas}
              nodeCanvasObjectMode={() => "after"}
              warmupTicks={1}
              cooldownTime={5000}
              d3AlphaDecay={0.01}
              d3VelocityDecay={0.25}
              linkWidth={0.25}
            />
          </div>
          <ControlPanel>
            <Button
              danger
              size="large"
              onClick={clearSelection}
              disabled={selectedNodeIds.size === 0}
            >
              Clear Selection
            </Button>
            <Switch
              checked={debugMode}
              onChange={(isChecked) => {
                setDebugMode(isChecked);
              }}
              checkedChildren="Hide UL"
              unCheckedChildren="Show UL"
            />
            {!isKnowledgeLookup && (
              <Switch
                checked={isShowSolutionNodes}
                onChange={(isChecked) => {
                  setIsShowSolutionNodes(isChecked);
                  !isChecked && setUserHoverNodeId(undefined);
                }}
                disabled={isGraphLoading}
                checkedChildren="Solution nodes"
                unCheckedChildren="All nodes"
              />
            )}
            <Tooltip placement="top" title="Zoom to fit">
              <Button
                size="large"
                onClick={() => graphZoomToFit()}
                disabled={isGraphLoading}
                icon={<FullscreenExitOutlined style={{ fontSize: "20px" }} />}
              />
            </Tooltip>

            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                size="large"
                icon={<ZoomOutOutlined />}
                onClick={handleZoomOut}
                disabled={isGraphLoading}
              />
              <Button
                size="large"
                icon={<ZoomInOutlined />}
                onClick={handleZoomIn}
                disabled={isGraphLoading}
              />
            </div>
          </ControlPanel>
        </div>
      )}
    </ResizeDetector>
  );
};
