import { NodeDto } from "@dip/data-access/api-types";
import { Card } from "@dip/ui/components/cards";
import { Tag } from "@dip/ui/components/tags";

const ResolvedTag = ({ resolved }: { resolved: boolean }) => (
  <Tag color={resolved ? "green" : "blue"}>
    {resolved ? "Resolved" : "Coined"}
  </Tag>
);

type DomainNodeCardProps = {
  node: NodeDto;
};

export const DomainNodeCard = ({ node }: DomainNodeCardProps) => (
  <Card>
    <Card.Header title={node.nickname} description={node.nodeId} />
    <Card.Body>
      <p>Expressed as: "{node.expressedIn}"</p>
      <ResolvedTag resolved={node.resolved} />
    </Card.Body>
  </Card>
);
