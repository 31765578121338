import { Outlet } from "react-router-dom";
import { ChannelProvider } from "ably/react";
import { useAppSelector } from "../hooks";
import { RealtimeChannel } from "./realtimeChannel.enum";

export const RealtimeProvider = () => {
  const tenantId = useAppSelector(({ auth }) => auth.tenantId);

  return (
    <ChannelProvider channelName={`${tenantId}:${RealtimeChannel.DOMAIN}`}>
      <Outlet />
    </ChannelProvider>
  );
};
