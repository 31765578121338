export enum ENV {
  DEVELOPMENT = "development",
  PROD = "production",
  TEST = "test",
}

type Auth0 = { AUDIENCE: string; CLIENT_ID: string; DOMAIN: string };

export type ApplicationConfig = {
  AUTH0: Auth0;
  DIP_API_BASE_URL: string;
  ENV: ENV;
  LAUNCH_DARKLY_CLIENT_TOKEN: string;
};
