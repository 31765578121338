import { createSlice } from "@reduxjs/toolkit";

type AuthState = {
  accessToken?: string;
  tenantId?: string;
};

const initialState: AuthState = {};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, action) => ({
      ...state,
      accessToken: action.payload,
    }),
    setTenantId: (state, action) => ({
      ...state,
      tenantId: action.payload,
    }),
  },
});

export const { setAccessToken, setTenantId } = authSlice.actions;
